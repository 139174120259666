
#testemunho{
    background-color: #f3f3f3;
    padding-block: 110px;
}
#testemunho h1{
    color:darkcyan;
    font-weight: 380;
}
#testemunho h3{
   text-align: center;
   font-size: 14pt;
   font-family: Montserrat;
}
.teste{
   background-color: darkcyan;
   color:#ffffff;
}
#testemunho h4{
    color:rgb(241, 166, 26);
}
#testemunho img{
    border-radius: 50%;
    width: 4%;
    min-height: 30px;
    max-height: 30px;
    margin: 20px;
    float: none;
    text-align: center;
    justify-content: center;
}

.conteudoT{
    color: black;
    font-size: large;
    text-align: justify;
    padding: 20px;
    padding-right: 20px;
    padding-left: 20px;
    line-height: 1.5;
    font-family: Montserrat; 
    background-color:darkcyan; 
   /*background-image: url("D:\Deny\render_site\pagina\public\Images\imag.jpg");*/
}
