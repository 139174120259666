#banner{
    padding-top: 70px;
    background-color: #f3f3f3;
          
}

.contentor{
    padding-left: 0px;
    padding-right: 0px;

}