#historia{
    padding-top: 90px;
    background-color:#f3f3f3;

}

.carta{
    max-height: 480px;
}
.conteudo{
    text-align: justify;
    padding: 20px;
    padding-right: 20px;
    padding-left: 20px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
    font-family: Montserrat;   
}

.titulo{
    text-align: center;

}
#historia img{
   max-height: 480px;
}

#historia h1{
    color:darkcyan;
    font-weight: 380;
    
}

#historia h5{
    color:#ffffff;
}
#historia h4{
    color:rgb(241, 166, 26);
}

 
  @media (min-width: 0px) and (max-width: 575.98px) { 
    .justify-content{
        padding-left: 150px;
        padding-right: 45px;
        font-size: 4.8pt;
        color: #0f0f0f99;
    } }

    @media (min-width: 576px) and (max-width: 767.98px) { 
        .justify-content{
            padding-left: 230px;
            padding-right: 60px;
            font-size: 8.5pt;
            color: #0f0f0f99; 
    } }

    @media (min-width: 768px) and (max-width:991.98px) { 
        .justify-content{
            padding-left: 310px;
            padding-right: 80px;
            font-size: 11pt;
            color: #0f0f0f99;
    } }

    @media (min-width: 992px) and (max-width:1199.98px) { 
        .justify-content{
            padding-left: 370px;
            padding-right: 100px;
            font-size: 12pt;
            color: #0f0f0f99;
    } }

    @media (min-width: 1200px) and (max-width:1399.98px) { 
        .justify-content{
            padding-left: 420px;
            padding-right: 110px;
            font-size: 13.5pt;
            color: #0f0f0f99;
    } }
    @media (min-width: 1400px) {
        .justify-content{
            padding-left: 450px;
            padding-right: 130px;
            font-size: 13.5pt;
            color: #0f0f0f99;
        }}