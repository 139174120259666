.nav-link{
    color: #fff;
}


.nav-link:hover {
    border-bottom: 2px solid black;
    transition: all 0.2s ease-out;
}
.btn:hover{
    background-color: rgb(218, 214, 20);
}

.lista{
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif ;
    font-size:12pt;
    font-style: italic;
}
