#footer{
    background-color: darkcyan;
    color:#fff;
}
.rodape{
    background-color: #f3f3f380;
    text-align: start;
    color: #0f0f0f;
}

#footer h5:hover{
    color:#ffffff;
    font-size: 16pt;
}

#fa{color:rgb(54, 54, 190)}
#fa:hover{color:#ffffff}

#tw{color:#ffffff}
#tw:hover{color:blue}

#ins{color:rgb(148, 123, 40)}
#ins:hover{color:red}

#mai{color:rgb(245, 47, 47)}
#mai:hover{color:#0f0f0f}
.icor{
    color:#0f0f0f;
}
.icor:hover{
    color:red;
    
}
.contatos{
    background-color: #f3f3f301;
}
.rede{
    margin-left: 10px;
    color:#ffffff;
}
.li{
    color: green;
    font-size: 13pt;
}

.li:hover{
    background-color:#0f0f0f20;
    color: #fff;
    font-size: 15pt;
}