.feature-box{
    text-align: center;
    padding: 5%;
}
#features{
  
    padding-block: 7%;
    background-color: #f3f3f3;
}
#features h1{
    color:darkcyan;
    font-weight: 380;
    
}
#features h3{
    line-height: 1.5;
    font-family: 'Montserrat';
    color: #0f0f0f;
}
#features h4{
    color:rgb(241, 166, 26);
}
#features h5{
    color:#0f0f0f90;
}
#features p{
    color: #ffffff;
    font-weight: 450;
    font-size:18px;
    line-height: 1.5;
}

.ft{
    background-color: darkcyan;
}
.icon{
    color:#ffffff;
    margin-bottom: 15px;
}
.icon:hover{
    color:#0f0f0f95;
    
}
