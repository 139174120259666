
 #equipe{
     background-color: #f3f3f3;
     padding-block: 100px;
 }
 #equipe p{
    font-size:clamp(1.75em, 1em +3vw, 2.5em);
}

 #equipe h1{
    color:darkcyan;
    font-weight: 380;
}
#equipe h4{
    color:rgb(241, 166, 26);
    text-align: center;
}

#equipe img{
    min-height:330px ;
    min-width: 330px;
}


 .conteudoE{
    color: #ffffff;
    font-size: large;
    text-align: justify;
    padding: 20px;
    padding-right: 20px;
    padding-left: 20px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
    font-family:Montserrat; 
    background-color:darkcyan;  
   
}

@media (min-width: 768px) and (max-width:991.98px) { 
    .justify-contentor{
        padding-left: 5px;
        font-size: 11pt;
        color:#ffffff;
} }